import React from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { closeTutorials } from "../../../app/Reducers/TutorialsReducer";
interface TutorialBarInterface {
  onSwitchMode: () => void;
}

const TutorialBar: React.FC<TutorialBarInterface> = ({ onSwitchMode }) => {
  const tutorials: any = useSelector((state: any) => state.tutorial.tutorials);
  const isOpen = useSelector((state: any) => state.tutorial.isOpen);

  const dispatch = useDispatch();

  const handleCloseTutorials = () => {
    dispatch(closeTutorials());
  };
  // if (!isOpen) return null;
  // Sidebar

  return (
    <div className="inline-flex h-full w-6/12 flex-col gap-4 border-l p-2 dark:border-main-pink-main ">
      <div className="flex items-center justify-between rounded-t border-b border-gray-600 p-4">
        <h3 className="flex items-center justify-center text-2xl font-bold text-black dark:text-white">
          Tutorials
        </h3>
        <div className="flex gap-2" id="actions">
          <button
            type="button"
            className="ml-auto inline-flex items-center justify-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="defaultModal"
            onClick={handleCloseTutorials}
          >
            {React.createElement(AiOutlineCloseCircle, { size: 30 })}
            <span className="sr-only">Close modal</span>
          </button>
          <button
            type="button"
            className="ml-auto inline-flex items-center justify-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="defaultModal"
            onClick={onSwitchMode}
          >
            {React.createElement(HiOutlineSwitchHorizontal, { size: 30 })}
            <span className="sr-only">Toggle Mode</span>
          </button>
        </div>
      </div>
      {tutorials &&
        tutorials.map((tutorial: any, index: number) => (
          <div
            key={index}
            className="  flex flex-col  gap-2 overflow-auto rounded-lg border-2 bg-white p-1 text-black shadow 
        duration-500 dark:border-main-gray-layer dark:bg-main-gray-layer dark:text-white dark:shadow dark:shadow-main-blue-900
        "
          >
            <h2 className="mb-2 text-xl font-semibold">{tutorial.title}</h2>
            <p className="mb-4">{tutorial.description}</p>
            <iframe
              className="h-auto w-full rounded-lg"
              src={tutorial.videoURL}
              title={tutorial.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
    </div>
  );
};

export default TutorialBar;
