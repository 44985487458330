import React, { useState } from "react";
import { FaAnglesLeft, FaAnglesRight } from "react-icons/fa6";
import { NavLink, useLocation } from "react-router-dom";

import { useSelector } from "react-redux";
import { selectCurrentBusinessMode } from "../../app/Reducers/AppConfig";
import { EBusinessType } from "../../app/Reducers/AppConfig/types";
import "./index.css";
import { dropshipping_menus, extraMenus, pod_menu } from "./config/menus";

interface CustomSideElementInterface {
  title: string;
  href: string;
  isActive?: boolean;
  iconPath: string;
  isOpen: boolean;
  isActiveBgColor?: string;
  isActiveTextColor?: string;
}
const CustomSideElement = ({
  title,
  href,
  isActive = false,
  iconPath,
  isOpen,
  isActiveBgColor = "[#6839ee]/20",
  isActiveTextColor = "#6839ee",
}: CustomSideElementInterface) => {
  return (
    <NavLink
      className={`flex w-full flex-col justify-start gap-2.5 p-2
      text-[#79757f]  
      ${
        isActive
          ? `rounded-lg bg-${isActiveBgColor} text-${isActiveTextColor}`
          : `hover:text-${isActiveTextColor} `
      }
      ${isOpen ? "md:items-start" : " items-center"}

      `}
      style={{ color: isActive ? isActiveTextColor : "#79757f" }}
      to={href}
    >
      <div
        className={`inline-flex h-6 items-center justify-center 
         gap-4 md:justify-start ${isOpen ? "md:ml-2 " : ""}`}
      >
        <img alt="" className="" src={iconPath}></img>

        <div className={`text-16 hidden  ${isOpen ? "md:block" : " "}`}>
          {title}
        </div>
      </div>
    </NavLink>
  );
};

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);

  const currentBusinessType = useSelector(selectCurrentBusinessMode);
  const currentPath = useLocation().pathname;
  return (
    <div
      className={`inline-flex h-full w-16 flex-col items-start justify-between gap-8   px-2
      pb-8 pt-5 text-black duration-500  ${isOpen ? "md:w-56 md:px-4" : ""} `}
    >
      <div className="flex w-full flex-col">
        <a
          id="logo"
          href="/"
          className=" flex w-full items-center justify-center gap-3"
        >
          <img
            className="relative  rounded-full"
            src="/assets/logo/main.svg"
            alt="Launchify Logo"
          />
          {isOpen && (
            <img
              className="relative hidden md:block"
              src="/assets/logo/logo-text.svg"
              alt="Launchify Logo"
            />
          )}
        </a>
        <div className="relative  top-[13px] hidden w-full md:block">
          <div
            id="openclose"
            className="absolute bottom-2 end-0 z-10 ml-auto mr-[-25px]  cursor-pointer 
           justify-end rounded-full
         border bg-white p-2 text-black  "
            onClick={() => setIsOpen((prev) => !prev)}
          >
            {isOpen
              ? React.createElement(FaAnglesLeft, { size: 15 })
              : React.createElement(FaAnglesRight, { size: 15 })}
          </div>
        </div>

        <div
          id="top-section"
          style={{ maxHeight: "calc(100vh - 4rem)", overflowY: "auto" }}
          className="mt-[56px] flex flex-col items-center justify-start gap-2 self-stretch overflow-x-hidden" // Add `justify-start` to place it at the start
        >
          {(currentBusinessType === EBusinessType.POD
            ? pod_menu
            : dropshipping_menus
          ).map(({ title, href, iconPath }: any, idx: number) => {
            const isActive = currentPath === href;
            return (
              <CustomSideElement
                title={title}
                href={href}
                iconPath={iconPath}
                key={idx}
                isActive={isActive}
                isOpen={isOpen}
              />
            );
          })}
        </div>
      </div>
      <div
        id="bottom-section"
        className="flex flex-col items-center justify-end gap-4 self-stretch"
      >
        {" "}
        <div
          className="flex w-full flex-col items-start justify-start gap-2.5"
          id="helper-section"
        >
          {extraMenus.map(
            ({ title, href, icon, iconPath }: any, idx: number) => {
              const isActive = currentPath === href;
              return (
                <CustomSideElement
                  title={title}
                  href={href}
                  iconPath={iconPath}
                  key={idx}
                  isActive={isActive}
                  isOpen={isOpen}
                  isActiveBgColor="[#b349ed]/[0.08]"
                  isActiveTextColor="#9b17e4"
                />
              );
            },
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
