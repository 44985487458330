import { useState, useEffect } from "react";
import { initAuth } from "../clients/firebase";
import { onAuthStateChanged } from "firebase/auth";

interface UserInfo {
  _id?: string;
  email?: string | null;
  firstName?: string;
  lastName?: string;
  username?: string;
}

const useUserInfo = (): UserInfo => {
  const auth = initAuth();
  const [userInfo, setUserInfo] = useState<UserInfo>({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Here you can retrieve additional user info if stored in Firebase or a separate database
        const email = user.email;
        // Placeholder for other user info, e.g., retrieved from a database
        const firstName = ""; // Replace with actual value
        const lastName = ""; // Replace with actual value
        const _id = user.uid; // Firebase UID can be used as _id

        let username = "";
        if (user.displayName) {
          username += user.displayName;
        }
        setUserInfo({ email, firstName, lastName, username, _id });
      } else {
        // Handle the case when user is not logged in
        setUserInfo({});
      }
    });

    // Cleanup the subscription
    return () => unsubscribe();
  }, [auth]);

  return userInfo;
};

export default useUserInfo;
