import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { Epic, ofType } from "redux-observable";
// import { take, tap } from "rxjs";
import { AllStageDataListItem } from "./index.d";
import { StoreState } from "../../Types";

export const ALL_STAGE_LIST_PREFIX = "ALL_STAGE_LIST";

// Entity adapter for CRUD operations on AllStageDataListItem
export const allStageDataListEntity =
  createEntityAdapter<AllStageDataListItem>();

// Redux slice containing our reducer logic and action creators
export const allStageDataListSlice = createSlice({
  name: ALL_STAGE_LIST_PREFIX,
  initialState: allStageDataListEntity.setAll(
    allStageDataListEntity.getInitialState(),
    [],
  ),
  reducers: {
    initialize(state, action) {
      allStageDataListEntity.setAll(state, action.payload);
    },
    addItem(state, action) {
      if (Array.isArray(action.payload)) {
        allStageDataListEntity.addMany(state, action.payload);
        return;
      }
      allStageDataListEntity.addOne(state, action.payload);
    },
    updateItem(state, action: PayloadAction<AllStageDataListItem>) {
      allStageDataListEntity.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeItem(state, action) {
      if (Array.isArray(action.payload)) {
        allStageDataListEntity.removeMany(state, action.payload);
        return;
      }
      allStageDataListEntity.removeOne(state, action.payload);
    },
  },
});

const allStageDataListReducer = allStageDataListSlice.reducer;

export const allStageDataListSelector = allStageDataListEntity.getSelectors(
  (state: StoreState) => state.allStageDataList,
);

export const allStageDataListAction = allStageDataListSlice.actions;

export default allStageDataListReducer;
