import { createSlice } from "@reduxjs/toolkit";
import { FontState } from "./index.d";
const initialState: FontState = {
  loadedFonts: [],
};

const editorFontsReducer = createSlice({
  name: "font",
  initialState,
  reducers: {
    fontLoaded: (state, action) => {
      state.loadedFonts.push(action.payload);
    },
  },
});

export const { fontLoaded } = editorFontsReducer.actions;
export default editorFontsReducer.reducer;
