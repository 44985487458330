// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDlizSx_AsT7HqLOIR-ToNeJ7ktz50uwMA",
  authDomain: "lunar-works-394416.firebaseapp.com",
  projectId: "lunar-works-394416",
  storageBucket: "lunar-works-394416.appspot.com",
  messagingSenderId: "1082564981810",
  appId: "1:1082564981810:web:cefa7d660dec1a06fcf82b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const initFirebase = () => {
  return app;
};
export const initAuth = () => {
  return getAuth(app);
};

export const getCurrentUser = () => {
  const auth = getAuth(app);
  
  return auth.currentUser; // This will be the user object or null
};
