import "./App.css";

import { useRoutes } from "react-router-dom";

import { AuthProvider } from "./contexts/AuthContext";
import routes from "./router";
import { ToastContainer } from "react-toastify";

function App() {
  // Using the useRoutes hook to handle routing based on the defined routes
  const content = useRoutes(routes());

  return (
    <AuthProvider>
      <ToastContainer toastStyle={{ width: "fit-content" }} />
      <div className="App font-outfit">{content}</div>
    </AuthProvider>
  );
}

export default App;
