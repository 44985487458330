import React, { useState } from "react";
import Draggable from "react-draggable";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { ResizableBox } from "react-resizable";
import { closeTutorials } from "../../../app/Reducers/TutorialsReducer";

import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import "react-resizable/css/styles.css";
interface TutorialPopupInterface {
  onSwitchMode: () => void;
}
export interface ResizeCallbackDataInterface {
  node: HTMLElement;
  size: { width: number; height: number };
  handle: any;
}
const TutorialPopup: React.FC<TutorialPopupInterface> = ({ onSwitchMode }) => {
  const tutorials: any = useSelector((state: any) => state.tutorial.tutorials);
  const [containerSize, setContainerSize] = useState({
    width: 400,
    height: 800,
  });
  const [defaultPosition, setDefaultPosition] = useState({ x: 0, y: 0 });
  const isOpen = useSelector((state: any) => state.tutorial.isOpen);

  const dispatch = useDispatch();

  const handleCloseTutorials = () => {
    dispatch(closeTutorials());
  };

  return (
    <div className="absolute h-0 w-0">
      <Draggable
        handle=".drag-handle"
        bounds=".drag-limit"
        defaultPosition={{
          x: window.innerWidth / 2,
          y: 50,
        }}
      >
        <ResizableBox
          width={containerSize.width}
          height={containerSize.height}
          className="box absolute z-[9999] flex flex-col rounded-lg border bg-white shadow-lg dark:border-main-pink-main dark:bg-main-gray-layer"
          resizeHandles={["nw", "ne"]}
        >
          <div className="drag-handle flex items-center justify-between rounded-t border-b border-gray-600 p-4">
            <h3 className="flex items-center justify-center text-2xl font-bold text-black dark:text-white">
              Tutorials
            </h3>
            <div className="flex gap-2" id="actions">
              <button
                type="button"
                className="ml-auto inline-flex items-center justify-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="defaultModal"
                onClick={handleCloseTutorials}
              >
                {React.createElement(AiOutlineCloseCircle, { size: 30 })}
                <span className="sr-only">Close modal</span>
              </button>
              <button
                type="button"
                className="ml-auto inline-flex items-center justify-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="defaultModal"
                onClick={onSwitchMode}
              >
                {React.createElement(HiOutlineSwitchHorizontal, {
                  size: 30,
                })}
                <span className="sr-only">Toggle Mode</span>
              </button>
            </div>
          </div>
          <div className="max-h-max flex-1 gap-4 overflow-auto p-2">
            {tutorials &&
              tutorials.map((tutorial: any, index: number) => (
                <div
                  key={index}
                  className="mb-2 flex flex-col gap-2 rounded-lg border-2 bg-white p-1 text-black shadow duration-500 dark:border-main-gray-layer dark:bg-main-gray-layer dark:text-white dark:shadow dark:shadow-main-blue-900"
                >
                  <h2 className="mb-2 text-xl font-semibold">
                    {tutorial.title}
                  </h2>
                  <p className="mb-4">{tutorial.description}</p>
                  <iframe
                    className="h-auto w-full rounded-lg"
                    src={tutorial.videoURL}
                    title={tutorial.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              ))}
          </div>
        </ResizableBox>
      </Draggable>
    </div>
  );
};

export default TutorialPopup;
