import React, { useState } from "react";
import TutorialBar from "./TutorialBar";
import TutorialPopup from "./TutorialPopup";

import { useSelector } from "react-redux";
const TutorialContainer: React.FC = () => {
  const [isPopupMode, setIsPopupMode] = useState(false);

  const handleToggleMode = () => {
    setIsPopupMode((prevMode) => !prevMode);
  };
  const isOpen = useSelector((state: any) => state.tutorial.isOpen);

  if (!isOpen) return null;
  return (
    <>
      {isPopupMode ? (
        <TutorialPopup onSwitchMode={handleToggleMode} />
      ) : (
        <TutorialBar onSwitchMode={handleToggleMode} />
      )}
    </>
  );
};

export default TutorialContainer;
