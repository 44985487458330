import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { initAuth } from "../../clients/firebase";
import useOutsideClick from "../../hooks/useOutsideClick";
import useUserInfo from "../../hooks/useUserInfo";
import Dropdown, { DropdownItemInterface } from "./Dropdown";
import {
  APPConfigAction,
  selectCurrentBusinessMode,
} from "../../app/Reducers/AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { EBusinessType } from "../../app/Reducers/AppConfig/types";

const Navbar: React.FC = () => {
  const { email, firstName, lastName, username } = useUserInfo();
  const navigate = useNavigate();
  const firebaseAuth = initAuth();
  const dispatch = useDispatch();
  const handleChangeBusinessType = (
    businessType: EBusinessType | null = null,
  ) => {
    dispatch(APPConfigAction.changeMode(businessType));
  };
  const currentBusinessType = useSelector(selectCurrentBusinessMode);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownOutsideClickRef = useOutsideClick(() => toggleDropdown());

  const handleSignOut = () => {
    firebaseAuth.signOut();

    // Navigate to '/'
    navigate(0);
  };
  const toggleDropdown = () => {
    setIsDropdownVisible((prev) => !prev);
  };
  const dropdownItems: DropdownItemInterface[] = [
    {
      label: "My Profile",
      onClick: () => {
        navigate("/profile");
        toggleDropdown();
      },
      icon: "/assets/sidebar/dropdown/profile.svg",
    },
    {
      label: "Settings",
      onClick: () => console.log("Settings clicked"),
      icon: "/assets/sidebar/dropdown/settings.svg",
    },
    {
      label: "Sign Out",
      onClick: handleSignOut,
      icon: "/assets/sidebar/dropdown/logout.svg",
    },
  ];
  return (
    <nav
      className="w-max-max mx-auto flex h-16 flex-row items-center justify-between bg-white 
 p-2 duration-500 "
    >
      <div className="ml-auto inline-flex items-center gap-5 self-stretch ">
        <div className="relative inline-block text-left">
          <button
            onClick={() => handleChangeBusinessType()}
            className="inline-flex h-10 items-center justify-start gap-4 rounded-[10px] bg-gradient-to-r from-[#efe9ff] via-[#f8ebff] to-[#f3eaff] px-4 py-2"
          >
            <div className="flex items-center justify-start gap-2">
              <img
                className="relative h-5 w-5"
                src="assets/navbar/gallery.svg"
              ></img>
              <div className="flex items-center justify-start gap-1">
                <div className="font-['Outfit'] text-sm font-medium leading-none text-[#1c1a22]">
                  {currentBusinessType?.toUpperCase()}
                </div>
                <div className="font-['Outfit'] text-xs font-normal leading-none text-[#48454e]">
                  business model
                </div>
              </div>
            </div>
            <div className="flex h-5 w-5 items-center justify-center gap-2.5 rounded bg-[#6839ee]/20">
              <img
                className="flex h-4 w-4 items-center justify-center px-1 py-[5.50px]"
                src="assets/navbar/arrow-down.svg"
              />
            </div>
          </button>
        </div>

        <div className="relative inline-block text-left">
          <button
            onClick={toggleDropdown}
            className="flex flex-shrink-0 flex-grow-0 items-center justify-start gap-6"
          >
            <div className="relative flex h-10 w-10 flex-shrink-0 flex-grow-0 flex-col items-center justify-center gap-2.5 overflow-hidden rounded-[20px] border border-[#6839ee]/[0.16] bg-[#6839ee]/[0.16] p-2.5">
              <p className="flex-shrink-0 flex-grow-0 text-left text-lg font-medium text-[#7354f0]">
                {username ? username[0] : ""}
              </p>
            </div>
          </button>

          {isDropdownVisible && (
            <Dropdown
              items={dropdownItems}
              dropdownOutsideClickRef={dropdownOutsideClickRef}
            />
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
