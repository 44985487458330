import { BsBack, BsTagsFill } from "react-icons/bs";
import { FaToolbox } from "react-icons/fa";
import { HiBuildingStorefront } from "react-icons/hi2";
import { IoMdSettings } from "react-icons/io";
import { MdSpaceDashboard } from "react-icons/md";
import { PiPlugsFill } from "react-icons/pi";
import { MdPermContactCalendar } from "react-icons/md";

export const extraMenus = [
  {
    title: "Consultation",
    href: "/consultation",
    icon: MdPermContactCalendar,
    iconPath: "/assets/sidebar/phone.svg",
  },
  {
    title: "Tutorials",
    href: "/tutorials",
    icon: FaToolbox,
    iconPath: "/assets/sidebar/tutorials.svg",
  },
  {
    title: "Learning hub",
    href: "/learningHub",
    icon: MdSpaceDashboard,
    iconPath: "/assets/sidebar/learn-hub.svg",
  },
  {
    title: "Contact Us",
    href: "/contactUs",
    icon: BsTagsFill,
    iconPath: "/assets/sidebar/contact-us.svg",
  },
];

export const pod_menu = [
  {
    title: "Home",
    href: "/",
    icon: FaToolbox,
    iconPath: "/assets/sidebar/home.svg",
  },
  {
    title: "My Products",
    href: "/myProducts",
    icon: BsTagsFill,
    iconPath: "/assets/sidebar/my-products.svg",
  },
  {
    title: "Store Builder",
    href: "/storeBuilder",
    icon: HiBuildingStorefront,
    iconPath: "/assets/sidebar/store-builder.svg",
  },

  {
    title: "Integrations",
    href: "/integration",
    icon: PiPlugsFill,
    iconPath: "/assets/sidebar/integration.svg",
  },
  {
    title: "Settings",
    href: "/profile",
    icon: IoMdSettings,
    iconPath: "/assets/sidebar/settings.svg",
  },
];
export const dropshipping_menus = [
  {
    title: "Home",
    href: "/",
    icon: FaToolbox,
    iconPath: "/assets/sidebar/home.svg",
  },
  {
    title: "Orders",
    href: "/dropshipping/orders",
    icon: BsBack,
    iconPath: "/assets/sidebar/orders.svg",
  },
  {
    title: "My Products",
    href: "/myProducts",
    icon: BsTagsFill,
    iconPath: "/assets/sidebar/my-products.svg",
  },
  {
    title: "Store Builder",
    href: "/storeBuilder",
    icon: HiBuildingStorefront,
    iconPath: "/assets/sidebar/store-builder.svg",
  },

  {
    title: "Integrations",
    href: "/integration",
    icon: PiPlugsFill,
    iconPath: "/assets/sidebar/integration.svg",
  },
  {
    title: "Settings",
    href: "/profile",
    icon: IoMdSettings,
    iconPath: "/assets/sidebar/settings.svg",
  },
];
