import React from "react";
import { IconType } from "react-icons";

export interface DropdownItemInterface {
  label: string;
  onClick?: () => void;
  icon: string;
}

export interface DropdownInterface {
  items: DropdownItemInterface[];
  username?: string;
  dropdownOutsideClickRef: any;
}

const Dropdown: React.FC<DropdownInterface> = ({
  items,
  username,
  dropdownOutsideClickRef,
}) => {
  return (
    <div
      className="absolute right-0 z-50 mt-2 w-36 
      origin-top-right divide-y divide-gray-100 rounded-md 
        bg-white shadow-lg outline-none"
      role="menu"
      ref={dropdownOutsideClickRef}
    >
      <div className="py-1">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <button
              className="flex w-full items-center justify-start 
              gap-2 border-r-4 border-transparent px-4
               py-2 text-left
               text-sm leading-5 text-[#1c1a22]
                transition duration-200 hover:bg-[#6839ee]/[0.08] hover:bg-gray-100"
              role="menuitem"
              onClick={item.onClick}
            >
              {item.icon && <img src={item.icon} />}
              {item.label}
            </button>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
