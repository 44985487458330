import { createSlice } from "@reduxjs/toolkit";
import { TutorialsState } from "./index.d";
const initialState: TutorialsState = {
  tutorials: [
    {
      title: "Tutorial 1",
      description: "Tutorial description 1",
      videoURL: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    },
    {
      title: "Tutorial 1",
      description: "Tutorial description 1",
      videoURL: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    },
    {
      title: "Tutorial 1",
      description: "Tutorial description 1",
      videoURL: "https://www.youtube.com/embed/dQw4w9WgXcQ",
    },
  ],
  isOpen: false,
};

const editorTutorialReducer = createSlice({
  name: "tutorial",
  initialState,
  reducers: {
    addTutorial: (state, action) => {
      state.tutorials.push(action.payload);
      state.isOpen = true;
    },
    closeTutorials: (state) => {
      state.isOpen = false;
    },
  },
});

export const { addTutorial, closeTutorials } = editorTutorialReducer.actions;
export default editorTutorialReducer.reducer;
