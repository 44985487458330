import { configureStore, EntityState } from "@reduxjs/toolkit";

import currentStageReducer from "../Reducers/CurrentStageReducer";
import { StageData } from "../Reducers/CurrentStageReducer/index.d";
import editorFontsReducer from "../Reducers/FontReducer";
import { FontState } from "../Reducers/FontReducer/index.d";
import stageDataReducer from "../Reducers/StageReducer";
import { StageDataListItem } from "../Reducers/StageReducer/index.d";
import { TutorialsState } from "../Reducers/TutorialsReducer/index.d";
import tutorialsReducer from "../Reducers/TutorialsReducer";
import { AllStageDataListItem } from "../Reducers/AllStagesReducer/index.d";
import allStageDataListReducer from "../Reducers/AllStagesReducer";
import { TAppConfig } from "../Reducers/AppConfig/types";
import APPConfigDataReducer from "../Reducers/AppConfig";

export type StoreState = {
  currentStageData: EntityState<StageData["attrs"]>;
  stageDataList: EntityState<StageDataListItem>;
  allStageDataList: EntityState<AllStageDataListItem>;
  editorFonts: EntityState<FontState>;
  tutorial: EntityState<TutorialsState>;
  appConfig: TAppConfig;
};
const configureKonvaEditorStore = (preloadedState?: StoreState) => {
  const store = configureStore({
    reducer: {
      stageDataList: stageDataReducer,
      currentStageData: currentStageReducer,
      allStageDataList: allStageDataListReducer,
      editorFonts: editorFontsReducer,
      tutorial: tutorialsReducer,
      appConfig: APPConfigDataReducer,
    },
    // Remove later
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false, // Disable the serializability check
      }),
  });
  return store;
};

export default configureKonvaEditorStore;
