interface ISelectBusinessCard {
  src: string;
  title: string;
  description: string;
  onClick: () => void;
}

const SelectBusinessCard = ({
  src,
  title,
  description,
  onClick,
}: ISelectBusinessCard) => {
  return (
    <button
      onClick={onClick}
      className="flex  flex-col items-center rounded-[15px] border-2 border-gray-100 px-[25px] py-[30px] hover:border-2 hover:border-[#8b72f3]"
    >
      <img className="" alt="card-image" src={src}></img>
      <div className="text-center font-['Outfit'] text-2xl font-medium leading-loose text-[#1c1a22]">
        {title}
      </div>
      <div className="text-center font-['Outfit'] text-lg font-normal leading-normal text-[#79757f]">
        {description}
      </div>
    </button>
  );
};

export default SelectBusinessCard;
