import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EBusinessType, TAppConfig } from "./types";
import { createSelector } from "reselect";
import { StoreState } from "../../Types";

export const APP_CONFIG_PREFIX = "APP_CONFIG";
export const APP_CONFIG_KEY = "APP_CONFIG";

// Utility function to load the state from local storage
const loadStateFromLocalStorage = (): TAppConfig | undefined => {
  try {
    const serializedState = localStorage.getItem(APP_CONFIG_KEY);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    console.error("Failed to load state from local storage:", err);
    return undefined;
  }
};

// Utility function to save the state to local storage
const saveStateToLocalStorage = (state: TAppConfig) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(APP_CONFIG_KEY, serializedState);
  } catch (err) {
    console.error("Failed to save state to local storage:", err);
  }
};

// Set the initial state by checking local storage first
const initialState: TAppConfig = loadStateFromLocalStorage() || {
  currentBusinessType: null,
};

export const APPConfigDataSlice = createSlice({
  name: APP_CONFIG_PREFIX,
  initialState,
  reducers: {
    // The action accepts a PayloadAction to specify the new mode directly
    changeMode: (state, action: PayloadAction<EBusinessType | null>) => {
      // Update the current business mode with the new mode provided in the action payload
      state.currentBusinessType = action.payload;

      // Save the updated state to local storage
      saveStateToLocalStorage(state);
    },
  },
});

// Exporting the reducer to be used in store configuration
const APPConfigDataReducer = APPConfigDataSlice.reducer;

// Selector to retrieve the current business mode from the state
export const selectCurrentBusinessMode = (state: StoreState) =>
  state.appConfig.currentBusinessType;

// Memoized selector for business modes
export const BusinessModes = createSelector(
  [selectCurrentBusinessMode],
  (currentBusinessMode) => ({
    pod: currentBusinessMode === EBusinessType.POD,
    dropshipping: currentBusinessMode === EBusinessType.DROPSHIPPING,
  }),
);

// Exporting the actions so they can be dispatched in the application
export const APPConfigAction = APPConfigDataSlice.actions;

export default APPConfigDataReducer;
