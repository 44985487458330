import React, { FC, ButtonHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

interface CustomButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string; // Custom prop for the button's label
  imageSource: string;
}
interface CustomFilledButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string; // Custom prop for the button's label
  iconPath?: any;
  active?: boolean;
  disabled?: boolean;
}
export const CustomButton: FC<CustomButtonProps> = ({
  label,
  imageSource,
  className,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        `flex h-14  flex-grow items-center justify-center gap-2.5 rounded-2xl border border-[#c9c4d0] p-2.5`,
        className,
      )}
      {...props}
    >
      <img
        src={imageSource}
        className="h-5 w-auto flex-shrink-0 flex-grow-0 object-cover"
      />

      <p className="flex-shrink-0 flex-grow-0 text-left text-sm font-medium text-[#1c1a22]">
        {label}
      </p>
    </button>
  );
};
export const CustomIconButton: FC<CustomFilledButtonProps> = ({
  iconPath,
  className,
  ...props
}) => {
  return (
    <button
      className={twMerge(`flex items-center justify-center `, className)}
      {...props}
    >
      <img src={iconPath} className="h-5 w-auto object-cover" />
    </button>
  );
};
export const CustomFilledButton: FC<CustomFilledButtonProps> = ({
  label,
  className,
  disabled = false,
  iconPath,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        "flex h-14 w-full flex-shrink-0 flex-grow-0 flex-col items-center justify-center gap-2.5 rounded-2xl p-2.5",
        className,
      )}
      style={{
        background:
          "linear-gradient(to right, #714bdb 0%, #8553ec 38%, #af48e9 100%)",
      }}
      {...props}
    >
      <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-2">
        {iconPath && <img className="" src={iconPath} />}
        <p className="flex-shrink-0 flex-grow-0 text-left text-base font-medium uppercase text-white">
          {label}
        </p>
      </div>
    </button>
  );
};

export const CustomFilledNormalButton: FC<CustomFilledButtonProps> = ({
  label,
  className,
  iconPath,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        ` flex h-[50px] w-full flex-shrink-0 flex-grow-0 flex-col items-center justify-center gap-2.5 rounded-2xl p-2.5 ${
          disabled ? " opacity-[0.32]" : ""
        }`,
        className,
      )}
      style={{
        background:
          "linear-gradient(to bottom, #7251ef 27.71%, #6c41e8 103.58%)",
      }}
      {...props}
      disabled={disabled}
    >
      <div className="relative flex  items-center justify-start gap-2">
        {iconPath && <img className="" src={iconPath} />}
        {label && (
          <p className="  text-left text-base font-medium text-white ">
            {label}
          </p>
        )}
      </div>
    </button>
  );
};
export const CustomNotFilledNormalButton: FC<CustomFilledButtonProps> = ({
  label,
  className,
  iconPath,
  disabled = false,
  active = false,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        ` flex h-[50px] w-full flex-shrink-0 flex-grow-0 flex-col
         items-center justify-center gap-2.5
         rounded-lg border-2 border-primary-blue-600 p-2.5 text-primary-blue-500 ${
           disabled ? " opacity-[0.32]" : ""
         }
         ${active ? "bg-[#656d7b]/[0.16]" : ""}
         `,

        className,
      )}
      {...props}
      disabled={disabled}
    >
      <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-2">
        {iconPath && <img className="" src={iconPath} />}
        {label && (
          <p className=" flex-shrink-0 flex-grow-0 text-left text-base font-medium  ">
            {label}
          </p>
        )}
      </div>
    </button>
  );
};

export const CustomEmptyNormalButton: FC<CustomFilledButtonProps> = ({
  label,
  className,
  iconPath,
  disabled = false,
  ...props
}) => {
  return (
    <button
      className={twMerge(
        ` flex h-[40px] w-full flex-shrink-0 flex-grow-0 flex-col  items-center
         justify-center gap-2.5 rounded-lg
          p-2.5 text-black ${disabled ? " opacity-[0.32]" : ""}`,
        className,
      )}
      {...props}
      disabled={disabled}
    >
      <div className="relative flex flex-shrink-0 flex-grow-0 items-center justify-start gap-2">
        {iconPath && <img className="" src={iconPath} />}
        {label && (
          <p className=" flex-shrink-0 flex-grow-0 text-left text-base font-medium  ">
            {label}
          </p>
        )}
      </div>
    </button>
  );
};
