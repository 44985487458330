import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { Epic, ofType } from "redux-observable";
// import { take, tap } from "rxjs";
import { StageDataListItem } from "./index.d";
import { StoreState } from "../../Types";

export const STAGE_LIST_PREFIX = "STAGE_LIST";

// Entity adapter for CRUD operations on StageDataListItem
export const stageDataListEntity = createEntityAdapter<StageDataListItem>();
// Redux slice containing our reducer logic and action creators
export const stageDataListSlice = createSlice({
  name: STAGE_LIST_PREFIX,
  initialState: stageDataListEntity.setAll(
    stageDataListEntity.getInitialState(),
    [],
  ),
  reducers: {
    initialize(state, action) {
      stageDataListEntity.setAll(state, action.payload);
    },
    addItem(state, action) {
      if (Array.isArray(action.payload)) {
        stageDataListEntity.addMany(state, action.payload);
        return;
      }
      stageDataListEntity.addOne(state, action.payload);
    },
    updateItem(state, action: PayloadAction<StageDataListItem>) {
      stageDataListEntity.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },
    removeItem(state, action) {
      if (Array.isArray(action.payload)) {
        stageDataListEntity.removeMany(state, action.payload);
        return;
      }
      stageDataListEntity.removeOne(state, action.payload);
    },
    clearItems(state, action) {
      stageDataListEntity.removeAll(state);
    },
  },
});

const stageDataListReducer = stageDataListSlice.reducer;

export const stageDataListSelector = stageDataListEntity.getSelectors(
  (state: StoreState) => state.stageDataList,
);

export const stageDataListAction = stageDataListSlice.actions;

export default stageDataListReducer;
